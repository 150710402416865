<template>
  <div>
    <v-card color="transparent" elevation="0" outlined>
      <v-card-title class="subtitle-1 text-uppercase"><strong>{{ $t('storage.integrations.brainag.history') }}</strong></v-card-title>
    </v-card>
    <v-timeline
      class="mt-4"
      dense
    >

      <v-timeline-item
        icon="mdi-file-document-outline"
        :fill-dot="true"
        color='white'
        icon-color="grey"
        small
        v-for="(item, index) in list"
        :key='index'
      >
        <v-list-item two-line class="ma-0 pa-0">
          <v-list-item-content>
            <v-list-item-title class="black--text font-weight-medium">
              <strong>{{ getDatasetName(item) }} <v-chip small> {{ list.length - index }} </v-chip></strong><br>
              <p class="subtitle-2 ma-0 pa-0">
                <strong>{{ item.type.documentType  }}:</strong>
                <v-tooltip bottom eager>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-on="on" v-bind="attrs"> {{ item.type.document | truncate(20) }} </span>
                  </template>
                  <span class="subtitle-2">{{ item.type.document }} </span>
                </v-tooltip>
              </p>
            </v-list-item-title>
            <v-list-item-subtitle>
              <p class="subtitle-2 ma-0 pa-0 my-2">{{ $t('storage.integrations.brainag.executedBy') }}: <strong class="black--text">{{ item.person }}</strong></p>
              <p class="subtitle-2 ma-0 pa-0">{{ (new Date(item.datetime)).toLocaleString() }}</p>
              <v-chip v-if="item.status === 'COMPLETED'" color="green lighten-5" text-color="green" x-small>
                <strong>{{ item.status }}</strong>
              </v-chip>
              <v-chip v-else color="orange lighten-4" text-color="yellow darken-4" x-small>
                <strong>{{ item.status }}</strong>
              </v-chip>
            </v-list-item-subtitle>
          </v-list-item-content>
          <!--<v-list-item-action class="ma-0 pa-0 mouse-pointer d-flex flex-row justify-center align-center align-content-center">
            <v-btn text icon small :to="{ name: 'list-check-documents' }">
              <v-icon small v-text="'mdi-file-document-outline'" />
            </v-btn>
          </v-list-item-action>-->
        </v-list-item>
      </v-timeline-item>
    </v-timeline>
  </div>
</template>

<script>
import _ from "lodash"
import { mapGetters } from 'vuex'

export default {
  name: "TimelineHistoryComponent",
  props: {
    list: {
      type: Array,
      require: false,
      default: function () {
        return []
      }
    }
  },

  computed: {
    ...mapGetters({
      documentType: 'storage/documentTypes'
    })
  },

  methods: {
    getDatasetName(item) {
      switch (item.type.documentType) {
        case 'CPF':
        case 'CNPJ':
          return _.find(this.documentType.datasets.document, { value: item.dataset }).name
        case 'CAR':
          return _.find(this.documentType.datasets.car, { value: item.dataset }).name
      }
    }
  }
};
</script>

<style scoped>
.v-chip.v-size--small,
.v-chip.v-size--x-small {
  border-radius: .3em !important;
}

:deep(.v-timeline-item__dot) {
  z-index: 0;
}
</style>