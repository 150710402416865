<template>
  <v-card outlined width="340px" max-width="340px">
    <v-card-text>
      <v-list class="ma-0 pa-0" dense v-if="items.length > 0">
        <v-list-item dense  class="ma-0 pa-0" v-for="(item, key) in items" :key="key" style="height: 30px; width: 300px; min-height: 30px">
          <v-list-item-content class="ma-0 pa-0">
            <p class="ma-0 pa-0">
              <strong>{{ $t(`storage.ambientCheck.SOIL_USE_CONSOLIDATED.${item.origem}`) }}:</strong>
            </p>
          </v-list-item-content>
          <v-list-item-action>
            <p class="ma-0 pa-0 textSUC">
              {{ item.area.toFixed(2) }} Ha
            </p>
          </v-list-item-action>
        </v-list-item>
      </v-list>
      <div v-else class="d-flex justify-start align-center align-content-center">
        <p class="ma-0 pa-0 mx-2">{{ $t('storage.integrations.brainag.withoutUseEarth') }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'SUCListComponent',
    props: {
      items: {
        type: Array,
        required: true
      }
    }
  }
</script>

<style scoped>
  .containerSUC {
    width: 350px;
  }
  .textSUC{
    font-size: 14px;
  }
</style>
