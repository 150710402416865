<template>
  <v-card outlined>
    <v-card-text>
      <v-list dense class="ma-0 pa-0" v-if="item.result.length > 0" >
        <div class="ma-0 pa-0 d-flex justify-start align-center align-content-center">
          <v-icon style="color: red; font-size: 16px">
            mdi-check-circle-outline
          </v-icon>
          <p class="ma-0 pa-0 mx-2"><strong>CNAE:</strong> {{ item.result[0].cnae }} | {{ item.result[0].ano }}</p>
        </div>
      </v-list>
      <div v-else class="d-flex justify-start align-center align-content-center">
        <p class="ma-0 pa-0">{{ $t('storage.integrations.brainag.withoutInfractions') }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: 'SlaveryListComponent',
    props: {
      item: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style scoped>

</style>