<template>
  <v-layout class="d-block">
    <v-row class="mb-12" v-if="integrations.BRAINAG">
      <v-col cols="4">
        <v-row>
          <v-col cols="12" v-if="((action === 'edit' || action === 'new') && (checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Write'] }]))))">
            <v-card outlined class="card-hover">
              <v-app-bar dense elevation="0" flat>
                <h3 class="font-weight-bold">{{ $t("storage.titles.searchSocioambiental") }}</h3>
                <v-spacer></v-spacer>
                <v-img
                  class="mx-4"
                  :src="require('@/assets/icons/shield-search.svg')"
                  contain
                  height="25px"
                  max-height="25px"
                  width="25px"
                  max-width="25px"
                />
              </v-app-bar>
              <v-card-text id="search" class="my-6">
                <v-row>
                  <v-col class="pr-1" cols="">
                    <v-select
                      v-model="document.type"
                      :items="documentTypes.types"
                      :label="$t('documents.fields.type')"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      item-color="primary"
                      onkeypress="return (event.charCode != 34)"
                      small-chips
                      clearable
                      @change="clearAgBrainFields()"
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    />
                  </v-col>
                  <v-col class="pl-1" cols="8">
                    <v-select
                      v-model="document.datasets"
                      :disabled="!document.type"
                      :items="getDatasets()"
                      color="primary"
                      dense
                      outlined
                      hide-details
                      item-color="primary"
                      item-text="name"
                      item-value="value"
                      :label="$t('storage.titles.datasets')"
                      multiple
                      onkeypress="return (event.charCode != 34)"
                      small-chips
                      clearable
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      v-model="document.criteria"
                      v-mask="setMask"
                      :clearable="!loadingSendQuery"
                      :disabled="
                        !(document.type && document.datasets.length > 0) ||
                        loadingSendQuery
                      "
                      :placeholder="setMask"
                      :rules="requireRules"
                      append-icon="mdi-magnify"
                      color="primary"
                      dense
                      outlined
                      clearable
                      onkeypress="return (event.charCode != 34)"
                      single-line
                      small-chips
                      v-on:keyup.86="replace"
                      v-on:keyup.ctrl.86="replace"
                    >
                      <template v-slot:append>
                        <v-btn
                          v-if="!loadingSendQuery"
                          :disabled="
                            !(document.criteria && document.criteria !== '')
                          "
                          icon
                          small
                          text
                          @click="searchData()"
                        >
                          <v-icon> mdi-magnify</v-icon>
                        </v-btn>
                        <v-progress-circular
                          class="mt-1"
                          v-else
                          indeterminate
                          size="15"
                          width="2"
                        ></v-progress-circular>
                      </template>
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" v-if="(checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read'] }])))">
            <v-card outlined class="card-hover">
              <v-app-bar dense elevation="0" flat>
                <h3 class="font-weight-bold">{{ $t("storage.titles.resultSocioambiental") }}</h3>
                <v-spacer></v-spacer>
                <v-img
                  class="mx-4"
                  :src="require('@/assets/icons/verify.svg')"
                  contain
                  height="25px"
                  max-height="25px"
                  width="25px"
                  max-width="25px"
                />
              </v-app-bar>
              <v-card-text id="search" class="my-6">
                <TimelineHistoryComponent
                  v-if="queryHistory && queryHistory.length > 0"
                  :list="queryHistory"
                />
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="8" v-if="(checkAdmin() ||
                (check([{ domain: 'CheckSocioambiental', permisions: ['Read'] }])))">
        <v-card outlined class="card-hover" id="sticky-widget">
          <v-app-bar dense elevation="0" flat>
            <h3 class="font-weight-bold">{{ $t("storage.titles.checkSocioambiental") }}</h3>
            <v-spacer></v-spacer>
            <v-img
              class="mx-4"
              :src="require('@/assets/icons/control.svg')"
              contain
              height="25px"
              max-height="25px"
              width="25px"
              max-width="25px"
            />
          </v-app-bar>
          <v-card-text>
            <div style="border: 1px solid rgba(128,128,128,0.10); border-radius: .3em" class="pa-2 my-2" v-if="latestQueries.cpf && latestQueries.cpf.length > 0">
              <!--------CPF-------->
              <CPFComponent
                :items="latestQueries.cpf"
              />
            </div>

            <div style="border: 1px solid rgba(128,128,128,0.10); border-radius: .3em" class="pa-2 my-2" v-if="latestQueries.cnpj && latestQueries.cnpj.length > 0">
              <!--------CNPJ------->
              <CNPJComponent
                :items="latestQueries.cnpj"
              />
            </div>

            <div style="border: 1px solid rgba(128,128,128,0.10); border-radius: .3em" class="pa-2 my-2" v-if="latestQueries.car && latestQueries.car.length > 0">
              <!--------CAR------->
              <CARBrainAgComponent
                :items="latestQueries.car"
              />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-layout>
</template>

<script>
  import _ from "lodash"
  import { mapGetters, mapActions } from "vuex"
  import CPFComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CPFComponent"
  import CNPJComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CNPJComponent"
  import CARBrainAgComponent from "@/module/configuration/storage/components/integrations/BRAINAG/csView/CAR/CARComponent.vue"
  import TimelineHistoryComponent from "@/module/configuration/storage/components/integrations/BRAINAG/searchView/TimelineHistoryComponent"
  import PermissionService from "@/services/PermisionService";

  const PROFILE = JSON.parse(localStorage.getItem("profile"))

  export default {
    components: {
      TimelineHistoryComponent,
      CARBrainAgComponent,
      CNPJComponent,
      CPFComponent,
    },
    name: "TabCheckLocation",
    props: {
      item: {
        type: Object,
        required: true,
      },
      action: {
        type: String,
        required: false,
        default: "show",
      },
    },

    data: () => ({
      loadingSendQuery: false,
      document: {
        type: "",
        criteria: "",
        datasets: [],
      },
      masks: {
        CPF: "###.###.###-##",
        CNPJ: "##.###.###/####-##",
        CAR: "AA-#######-NNNNNNNNNNNNNNNNNNNNNNNNNNNNNNNN",
      },
      integrations: {
        BRAINAG: null,
      },
    }),

    async created() {
      console.log(this.item)
      console.log(this.action)

      this.integrations.BRAINAG = _.find(PROFILE.integrationsCompany, {
        integrationCompanyType: "BRAINAG",
      })
      if (this.item.id) {
        if (this.integrations.BRAINAG) {
          await this.loadIntegrationsBrainAg()
        }
      }
    },

    computed: {
      ...mapGetters({
        documentTypes: "storage/documentTypes",
        queryHistory: "storage/queryHistory",
        latestQueries: "storage/latestQueries",
        requireRules: "general/requireRules",
      }),
      setMask() {
        switch (this.document.type) {
          case "CPF":
            return this.masks.CPF
          case "CNPJ":
            return this.masks.CNPJ
          case "CAR":
            return this.masks.CAR
        }
      },
    },

    methods: {
      ...mapActions({
        fetchBrainAgHistory: "storage/fetchBrainAgHistory",
        sendBrainAgQuery: "storage/sendBrainAgQuery",
        fetchLatestQueries: "storage/fetchLatestQueries",
      }),
      async loadIntegrationsBrainAg() {
        const promises = []

        promises.push(this.fetchBrainAgHistory([this.item.id, this.$toast]))
        promises.push(this.fetchLatestQueries([this.item.id, this.$toast]))
        await Promise.all(promises)
      },
      getDatasets() {
        switch (this.document.type) {
          case "CAR":
            return this.documentTypes.datasets.car
          case "CPF":
          case "CNPJ":
            return this.documentTypes.datasets.document
        }

        return []
      },
      clearAgBrainFields() {
        this.document.datasets = []
        this.document.criteria = ""
      },
      async searchData() {
        this.loadingSendQuery = true

        const body = {
          ...this.document,
          storageDeviceId: this.item.id,
        }

        await this.sendBrainAgQuery([body, this.$toast]).finally(() => {
          this.loadingSendQuery = false

          this.document = {
            type: "",
            criteria: "",
            datasets: [],
          }
        })
      },
      selectedCar(event) {
        console.log(event)
      },
      selectedCarLocation(event) {
        console.log(event)
      },
      replace(event) {
        this.event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll("\"", "")
      },
      // Check permission
      check(permissions) {
        return PermissionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermissionService.checkAdmin()
      },
    },
  }
</script>

<style scoped lang="scss">
  .fill-icon__color {
    filter: invert(20%) sepia(0%) saturate(0%) hue-rotate(180deg) brightness(400%) contrast(88%);
  }

  .card-hover {
    box-shadow: none;
    transition: all 200ms ease-in-out;

    &:hover {
      box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px,
      rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
    }
  }
</style>
