import { render, staticRenderFns } from "./ComplianceListComponent.vue?vue&type=template&id=0882c380&scoped=true"
import script from "./ComplianceListComponent.vue?vue&type=script&lang=js"
export * from "./ComplianceListComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0882c380",
  null
  
)

export default component.exports