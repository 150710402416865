<template>
  <v-card outlined>
    <v-card-text>
      <v-list dense class="ma-0 pa-0" v-if="items.length > 0">
        <div class="d-block mb-1" v-for="(item, index) in origins" :key="index">
          <div class="d-flex justify-start align-center align-content-center">
            <v-icon style="font-size: 16px"
                    :style="!verifyOrigin(item.key) ? 'color: green' : 'color: red'">
              {{ !verifyOrigin(item.key) ? 'mdi-check-circle-outline' : 'mdi-alert-circle-outline' }}
            </v-icon>
            <p class="ma-0 pa-0 mx-2">{{ item.key }}</p>
          </div>
        </div>
      </v-list>
      <div v-else class="d-flex justify-start align-center align-content-center">
        <p class="ma-0 pa-0 mx-2">{{ $t('storage.integrations.brainag.withoutInfractions') }}</p>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
  import _ from 'lodash'

  export default {
    name: 'ComplianceListComponent',
    props: {
      items: {
        type: Array,
        required: true
      }
    },

    computed: {
      verifyOrigin() {
        return (itemKey) => _.find(this.items, (i) => {
          return itemKey === i.origem
        })
      }
    },

    data: () => ({
      origins: [
        { key: "EMBARGO_LDI" },
        { key: "EMBARGO_SEMA" },
        { key: "EMBARGO_ICMBIO" },
        { key: "DESEMBARGO_SEMA" },
        { key: "PRODES_CERRADO" },
        { key: "PRODES_AMAZONIA" },
        { key: "PROPRIEDADES" },
        { key: "ASSENTAMENTOS" },
        { key: "UNIDADES_CONSERVACAO" },
        { key: "TERRAS_INDIGENAS" },
        { key: "QUILOMBOS" }
      ]
    })
  }
</script>

<style scoped>
</style>
